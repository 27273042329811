<template>
  <section>
    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>Órdenes</h1>

        <v-btn v-if="cannot('driver')" color="primary" :to="{ name: 'order-form' }">
          <v-icon left>mdi-plus</v-icon>
          Agregar
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          hide-details
          single-line
        />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="options.itemsPerPage"
        :loading="loading"
        :options.sync="options"
        :search="search"
        :server-items-length="total"
      >
        <template v-slot:item.products="{ value }">
          <ul>
            <li v-for="orderProduct in value" :key="orderProduct.id">
              {{ orderProduct.quantity }} -
              {{ orderProduct.product.name }} ({{ orderProduct.product.service.name }})
            </li>
          </ul>
        </template>

        <template v-slot:item.total="{ value }">
          {{ value | currency }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="light-blue lighten-3"
                :to="{ name: 'order-tracking', params: { uuid: item.uuid } }"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon small>mdi-truck-fast</v-icon>
              </v-btn>
            </template>
            <span>Seguimiento</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="info"
                :to="{ name: 'order-detail', params: { uuid: item.uuid } }"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Detalle</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="warning"
                :disabled="disableEdition(item)"
                :to="{ name: 'order-form', params: { uuid: item.uuid } }"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip top v-if="can('office_admin', 'operator')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="handleDelete(item)"
                small
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Eliminar</v-card-title>

        <v-card-text>
          ¿Desea eliminar la orden <b>{{ selectedItem.number }}</b
          >?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="darken-1" text @click="dialog = false"> Cancelar </v-btn>

          <v-btn color="red darken-1" text @click="remove"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
// API
import OrderAPI from '@/api/order';

// Mixins
import TableMixin from '@/mixins/TableMixin';

export default {
  name: 'OrderList',

  mixins: [
    TableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'Número', value: 'number' },
      { text: 'Cliente', value: 'customer.full_name' },
      { text: 'Productos', value: 'products' },
      { text: 'Total', value: 'total' },
      { text: 'Estatus', value: 'status' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
  }),

  async created() {
    this.api = new OrderAPI();
    await this.list();
  },

  methods: {
    disableEdition(order) {
      if (this.can('customer')) return order.paid;
      if (order.status === 'Creada' && order.paid) return false;

      const allowedStatus = ['Asignada', 'Creada', 'En camino a recolección'];

      return order.paid || !allowedStatus.includes(order.status);
    },
  },
};
</script>

<style lang="scss" scoped></style>
